<script lang="ts" setup></script>

<template>
  <div class="space-y-4">
    <h1 class="text-3xl font-bold underline">How to Use This Website</h1>

    <p>
      Hello! This website is still under construction. It will take some time
      before it's fully ready for public use. In the meantime, feel free to
      explore and try things out!
    </p>

    <h2 class="text-2xl font-semibold">Extracting ETT Logs from Quest</h2>
    <p>There are multiple ways to extract the logs:</p>

    <h3 class="text-xl font-semibold">Recommended Method</h3>
    <p>
      The easiest way is to start the game's file server. Once it's running, you
      can access the log files through your PC or phone browser by visiting the
      game's IP address.
    </p>
    <p>
      Watch this
      <a href="https://youtu.be/yGW7CmxHs5Y" class="text-blue-600 underline"
        >video tutorial</a
      >
      for step-by-step instructions.
    </p>

    <h3 class="text-xl font-semibold">Alternative Methods</h3>
    <p>You can also use SideQuest or ADB:</p>
    <p>
      <a
        href="https://adbdownload.com/"
        target="_blank"
        class="text-blue-600 underline"
        >Download ADB</a
      >
      and run the following command:
    </p>
    <pre class="dark:bg-gray-800 p-2 rounded-md text-sm">
adb pull "sdcard/Android/data/quest.eleven.forfunlabs/logs/"
  </pre
    >

    <h2 class="text-2xl font-semibold">Extracting Logs from PC</h2>
    <p>If you're using logs from the PC version, find them in this folder:</p>
    <pre class="dark:bg-gray-800 p-2 rounded-md text-sm">
.../Oculus/Software/for-fun-labs-eleven-table-tennis-vr/logs
  </pre
    >

    <h2 class="text-2xl font-semibold">Handling Large Log Files</h2>
    <p>
      If a log file exceeds the 100MB upload limit, you can clean it by removing
      unnecessary lines. Use one of the following commands:
    </p>

    <h3 class="text-xl font-semibold">Linux</h3>
    <pre class="dark:bg-gray-800 p-2 rounded-md text-sm">
sed '/UserCommunication/d' ALL-02.20.2025.7.47.58.PM.log > ALL-02.20.2025.7.47.58.PM.clean.log
  </pre
    >

    <h3 class="text-xl font-semibold">Windows PowerShell</h3>
    <pre class="dark:bg-gray-800 p-2 rounded-md text-sm">
Get-Content ALL-02.20.2025.7.47.58.PM.log | Where-Object { $_ -notmatch "UserCommunication" } | Set-Content ALL-02.20.2025.7.47.58.PM.clean.log
  </pre
    >
  </div>
</template>

<style scoped></style>
